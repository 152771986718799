.login {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background: url(../../images/bg-login.jpeg) no-repeat;
    background-size: cover;
    .form {
        width: 400px;
        padding: 30px;
        background: rgba(0,0,0,.1);
    }
}
@primary-color: #0AA679;@link-color: #24AF8A;@success-color: #0AA679;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@layout-sider-background: #2F4447;@layout-header-background: #2F4447;@layout-header-color: #fff;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),  0 9px 28px 8px rgba(0, 0, 0, 0.05);