.home {

    .bg {
        background: url(../../images/home.png) no-repeat;
        background-size: cover;
        position: fixed;
        opacity: 0.1;
        width: 100%;
        top: 0;    
        bottom: 0; 
        margin-top: 64px;
        // z-index: 1;       
    }
    .content {
        // 
        padding: 20px;
        // z-index: 9999;
        position: relative;
        .node {
            
            padding: 20px;
            border-radius: 5px;
            background: #fff;
            margin-bottom: 20px;

        }
    }
} 
@primary-color: #0AA679;@link-color: #24AF8A;@success-color: #0AA679;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@layout-sider-background: #2F4447;@layout-header-background: #2F4447;@layout-header-color: #fff;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),  0 9px 28px 8px rgba(0, 0, 0, 0.05);