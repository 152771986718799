.cus-layout {
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: @layout-header-color;
        cursor: pointer;
        margin-bottom: 0;
        .right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .name {
                margin-right: 10px;
                color: @layout-header-color;
                margin-bottom: 0;
                font-size: 14px;
            }
        }
    }
    .content {
        min-height: calc(100vh - 64px);
        .sider {
            position: relative;
            .trigger {
                position: absolute;
                color: @link-color;
                top: -40px;
                left: 20px;
                font-size: 1rem;
            }
        }
        .container {
            margin: 0;
            .page {
                padding: 20px;
            }
        }
    }
    .footer {

    }
}

.betweenFlex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.endFlex {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}
@primary-color: #0AA679;@link-color: #24AF8A;@success-color: #0AA679;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@layout-sider-background: #2F4447;@layout-header-background: #2F4447;@layout-header-color: #fff;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),  0 9px 28px 8px rgba(0, 0, 0, 0.05);